const GetDate = (date) => {
    const day = new Date(date).getDate()
    const months = new Date(date).getMonth() + 1
    const hours = new Date(date).getHours()
    const minutes = new Date(date).getMinutes()
    const seconds = new Date(date).getSeconds()

    return (
        `${hours}:${minutes}:${seconds} ${day}/${months}`
    )
}

export default GetDate