import React, {useEffect} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {statusConnectionsActions} from "../redux/actions/data-actions";
import GetDate from "../hooks/getDate";

function HistoryTable(props) {

    const dispatch = useDispatch()
    const statData = useSelector(state => state.statusConnections.closed)

    useEffect(() => {
        dispatch(statusConnectionsActions(props.token))
    }, [])

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={5} style={{ fontWeight: 600 }}>
                            History Connections
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Id Channel</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Ip</TableCell>
                        <TableCell align="right">User Agent</TableCell>
                        <TableCell align="right">End</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        statData.length > 0 ?
                            statData.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.my_field.title_channel}
                                    </TableCell>
                                    <TableCell align="right">{row.type}</TableCell>
                                    <TableCell align="right">{row.ip}</TableCell>
                                    <TableCell align="right">{
                                        row.user_agent.length > 30 ? row.user_agent.slice(0, 30) + '...' : row.user_agent
                                    }</TableCell>
                                    <TableCell align="right">{
                                        GetDate(row.deleted_at)
                                    }</TableCell>
                                </TableRow>
                            )) :
                            <TableRow>
                                <TableCell align="center" colSpan={5}>
                                    No Data
                                </TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default HistoryTable;