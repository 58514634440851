import React from 'react';
import Recharts from "./component/rechart";
import ActiveTable from "./component/activeTable";
import HistoryTable from "./component/historyTable";
import Grid from '@mui/material/Grid';

function App(props) {
    const token = props.match.params.data
    return (
        <>
            <Recharts token={token}/>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                    <ActiveTable token={token}/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <HistoryTable token={token}/>
                </Grid>
            </Grid>
        </>
    )
}

export default App;
