import {GET_STATUS_CLOSED, GET_STATUS_OPENED, GET_USER_ID} from "../types";

const initialState = {
    closed: [],
    opened: [],
    user_id: [],

}

export const statusConnections = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATUS_CLOSED:
            return {
                ...state, closed: action.payload
            }
        case GET_STATUS_OPENED:
            return {
                ...state, opened: action.payload
            }
        case GET_USER_ID:
            return {
                ...state, user_id: action.payload
            }
        default:
            return state
    }
}