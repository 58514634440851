import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import App from './App';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import thunk from "redux-thunk";
import {rootReducer} from "./redux/reducers/rootReducer";

const store = createStore(rootReducer, compose(
    applyMiddleware(
        thunk
    )
))

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path='/:data' component={App} />
            </Switch>
        </Router>
    </Provider>,
  document.getElementById('root')
);

