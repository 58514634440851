import {GET_DATA, GET_STATUS_CLOSED, GET_STATUS_OPENED, GET_USER_ID} from "../types";
import axios from "axios";

let baseUrl = ''

export const dataAction = (url) => {

    if (window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://127.0.0.1:3000') {
        baseUrl = 'http://localhost:8000/stat/'
    } else {
        baseUrl = window.location.origin + '/stat/'
    }

    const fullUrl = baseUrl + url
    return async dispatch => {
        try {
            const response = await axios(fullUrl)
            dispatch({type: GET_DATA, payload: response.data})
        } catch (e) {
            dispatch({type: GET_DATA, payload: []})
        }
    }
}

export const statusConnectionsActions = (url) => {

    if (window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://127.0.0.1:3000') {
        baseUrl = 'http://localhost:8000/notify_session/forrechart/'
    } else {
        baseUrl = window.location.origin + '/notify_session/forrechart/'
    }

    const fullUrl = baseUrl + url

    return async dispatch => {
        try {
            const response = await axios(fullUrl)
            dispatch({type: GET_STATUS_CLOSED, payload: response.data.closed})
            dispatch({type: GET_STATUS_OPENED, payload: response.data.opened})
            dispatch({type: GET_USER_ID, payload: response.data.user_id})
        } catch (e) {
            dispatch({type: GET_STATUS_CLOSED, payload: []})
            dispatch({type: GET_STATUS_OPENED, payload: []})
            dispatch({type: GET_USER_ID, payload: []})
        }
    }

}