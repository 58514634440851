import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactApexChart from 'react-apexcharts'
import {dataAction} from "../redux/actions/data-actions";

function Recharts(props) {

    const dispatch = useDispatch()
    const statData = useSelector(state => state.statData.data)
    const userId = useSelector(state => state.statusConnections.user_id)

    const state = {

        series: [
            {
                name: "Online",
                data: statData
            }
        ],
        options: {
            chart: {
                height: 380,
                width: "100%",
                type: "area",
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                type: 'datetime'
            },
            stroke: {
                curve: 'smooth',
                width: 1,
            },
            title: {
                text: userId.length > 0 ? 'Connections for user -- ' + userId[0].user_id : 'User not indefined',
                align: 'left'
            },
            markers: {
                size: 0,
                shape: 'square'
            }
        }
    }

    useEffect(() => {
        dispatch(dataAction(props.token))
    }, [])

    return (
        <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
    );
}

export default Recharts;
